/* eslint-disable filenames/match-exported */
import Error from "next/error";
import React, { ReactElement } from "react";

import Footer from "../components/Footer";
import Header from "../components/Header";

export default function Page(): ReactElement {
  return (
    <div>
      <div className="absolute w-full">
        <Header pageTitle="Not Found" />
      </div>
      <Error statusCode={404} />;
      <div className="-mt-32">
        <Footer />
      </div>
    </div>
  );
}
